import React, {
    useMemo,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import {
    CSSTransition
} from "react-transition-group";

import BackgroundImageLoad from "../BackgroundImageLoad";
import useWindowSize from "../../utilities/useWindowSize";

import defaultBanner from "../../img/default-event-banner.jpg";

function EventBanner({ event, backLink }) {
    const windowSize = useWindowSize();
    const [bannerLoaded, setBannerLoaded] = useState(false);
    const [logoLoaded, setLogoLoaded] = useState(false);

    const bannerUrl = useMemo(() => {
        if(!event) {
            return null;
        }
        if(!event.banner) {
            return defaultBanner;
        }
        if(windowSize.pixelWidth > 1920) {
            return event.banner.alternatives?.banner_detail_web_3840x2160?.url ?? event.banner.url;
        }
        return event.banner.alternatives?.banner_detail_web_1920x1080?.url ?? event.banner.url;
    }, [event, windowSize]);
    const logoUrl = useMemo(() => {
        if(!event?.logo) {
            return null;
        }
        return event.logo.alternatives?.logo_1080_width?.url ?? event.logo.url;
    }, [event]);

    return (
        <div className="event-banner mb-4">
            <CSSTransition in={bannerLoaded} appear timeout={500} classNames="fade" unmountOnExit>
                <div
                    className="event-banner-image"
                    style={{
                        backgroundImage: bannerUrl ? `url(${bannerUrl})` : undefined
                    }}
                />
            </CSSTransition>
            { bannerUrl && (
                <BackgroundImageLoad
                    src={ bannerUrl }
                    setLoaded={ setBannerLoaded }
                />
            )}
            { logoUrl && (
                <BackgroundImageLoad
                    src={ logoUrl }
                    setLoaded={ setLogoLoaded }
                />
            )}
            { backLink && (
                <div className="back-button-container">
                    <Link to={ backLink } className="back-button">
                        <i className="fa-regular fa-chevron-left mr-2"/>Ontdek
                    </Link>
                </div>
            )}
            <div className="event-banner-overlay">
                { logoUrl ? (
                    <div className="event-logo-container">
                        <CSSTransition in={ logoLoaded } appear timeout={500} classNames="fade" unmountOnExit>
                            <img
                                src={ logoUrl }
                                alt={ event.name }
                                className="event-logo"
                            />
                        </CSSTransition>
                    </div>
                ) : (
                    <h1 className="text-white mt-3 mb-2">
                        { event?.name }
                    </h1>
                )}
                <div className="event-description">
                    { event ? (
                        event.description
                    ) : (
                        <Skeleton count={ 3 }/>
                    )}
                </div>
            </div>
        </div>
    );
}
EventBanner.propTypes = {
    event: PropTypes.object,
    backLink: PropTypes.string
};

export default React.memo(EventBanner);

import {
    useState,
    useEffect,
    useMemo,
    useCallback
} from "react";

function useWindowSize() {
    const isClient = useMemo(() => {
        return typeof window === "object";
    }, []);

    const getSize = useCallback(() => {
        const width = isClient ? window.innerWidth : undefined;
        const height = isClient ? window.innerHeight : undefined;
        return {
            width,
            height,
            pixelWidth: window.devicePixelRatio * width,
            pixelHeight: window.devicePixelRatio * height,
            devicePixelRatio: window.devicePixelRatio,
        };
    }, [isClient]);

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        const handleResize = () => {
            setWindowSize(getSize());
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [getSize, isClient]);

    return windowSize;
}

export default useWindowSize;

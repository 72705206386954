import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../Loading";
import EventCard from "./EventCard";

function DiscoverList() {
    const [sections, setSections] = useState(null);
    const [error, setError] = useState(null);

    const refreshEvents = useCallback(async () => {
        setError(null);
        setSections(null);
        try {
            const response = await axios.get("/getDiscoverEvents");
            setSections(response.data.sections);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout bij het ophalen van de evenementen. Probeer het later opnieuw.");
        }
    }, []);

    useEffect(() => {
        refreshEvents();
    }, [refreshEvents]);

    return (
        <React.Fragment>
            <h1 className="text-white text-center mt-3 mb-2">
                Ontdek
            </h1>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !sections ? (
                <Loading white/>
            ) : (
                <div className="container">
                    { sections.map((section, index) => (
                        <React.Fragment key={ index }>
                            <h5 className="text-white mt-3">
                                { section.title }
                            </h5>
                            <div className="row">
                                { section.events?.map((event) => (
                                    <div key={event.id} className="col-sm-6 col-xl-4">
                                        <EventCard event={event}/>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(DiscoverList);
